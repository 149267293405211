.a-button {
    $root: &;
    @include reset-button;
    @include adjust-flex;
    background: $mountbatten-pink-linear;
    color: $pigment;
    font: inherit;
    margin: 0;
    padding: rem(17) rem(35);
    position: relative;
    transition: all $transition ease;
    width: 100%;

    @include tablet-down {
        padding: rem(10) rem(16);
    }

    &:not(#{$root}-secondary) {
        border-radius: rem(100);

        &::before {
            // background: $pale-gold-linear;
            background: transparent url('~assets/images/bg-button.png') no-repeat center/100% auto;
            border-radius: rem(100);
            content: '';
            height: calc(100% - #{rem(18)});
            position: absolute;
            width: calc(100% - #{rem(18)});

            @include mobile-down {
                height: calc(100% - #{rem(12)});
                width: calc(100% - #{rem(12)});
            }
        }
    }

    span {
        color: $pigment;
        font-weight: 900;
        position: relative;
        @include font-base(24, 33);
        /* margin-top: rem(8); */

        #{$root}-secondary & {
            @include font-base(20, 27);
        }

        @include tablet-down {
            @include font-base(20, 27);

            #{$root}-secondary & {
                @include font-base(18, 24);
            }
        }
    }

    // variants
    &-secondary {
        background: $inner-border-gold-linear;
        border-radius: rem(10);
        box-shadow: 0 3px 10px rgba($black, 0.6);
        height: rem(48);

        &::before {
            background-clip: padding-box, border-box;
            background-image: $inner-border-gold-linear;
            background-origin: border-box;
            border: solid rem(2.5) transparent;
            border-radius: rem(10);
            content: '';
            position: absolute;
            height: calc(100% + #{rem(2.5)});
            width: calc(100% + #{rem(2.5)});
            z-index: -1;
        }
    }

    &-blue {
        border-radius: rem(10) !important;

        &::before {
            background: $blue-linear-button !important;
            border-radius: rem(10) !important;
        }

        span {
            color: $white;
        }
    }

    &-uppercase {
        text-transform: uppercase;
    }

    &-h50 {
        height: rem(50);
        padding: rem(16);

        &:not(#{$root}-secondary) {
            &::before {
                height: calc(100% - 11px);
                width: calc(100% - 11px);
            }
        }

        span {
            @include font-base(20, 27);
        }
    }

    &-warning {
        background: none;
        border-radius: rem(12) !important;

        &::before {
            border-radius: rem(12) !important;
        }
    }

    &-active {
        background: $pale-gold-linear1;
        box-shadow: none;
    }

    &-disabled {
        filter: grayscale(1);
    }

    // sizes
    &-sm {
        span {
            @include font-base(20, 27);
        }

        &:not(#{$root}-secondary) {
            padding-bottom: rem(11);
            padding-top: rem(8);
        }

        #{$root}-secondary & {
            @include font-base(18, 24);
        }
    }

    // color
    @include style-color();

    &:disabled,
    &-loading {
        pointer-events: none;
    }

    &-loading {
        position: relative;
    }

    &_icon {
        margin-left: rem(13);

        @include tablet-down {
            margin-left: rem(10);

            .a-icon {
                height: rem(13);
                width: rem(13);
            }
        }
    }

    &_loading {
        img {
            width: rem(24);
        }
    }
}