.p-manualInput {
    height: 100svh;
    padding-bottom: rem(28);
    color: $white;
    background: linear-gradient(90deg,
    #0189d0 2.24%,
    #0181c9 7.34%,
    #015ea9 34.87%,
    #004492 60.36%,
    #003484 83.81%,
    #002f7f 102.16%);

    &_container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: rem(120);
    }

    &_input {
        margin-top: rem(8);
        margin-bottom: rem(24);
        border-radius: rem(24);
        outline: none;
        border-width: 0;
        height: rem(40);
        width: 100%;
        padding: 0 rem(16);
    }
}
