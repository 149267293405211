:root {
    --bg-color-primary: #{$eerie-black};
    --bg-color-feature: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='1443' height='693' fill='none'%3e%3cpath fill='%23ffffff' d='M202.197 440.5C93.284 485.7 17.685 555.333 0 584.5v108h1443v-212c-23.02-31.5-101.62-71-145.66-77-59.9-8.16-181.74 5.5-275.84-182C926.902 34 821.603 0 722.5 0c-89 0-179.948 41.5-284.557 221.5-83.688 144-192.034 206-235.746 219Z'/%3e%3c/svg%3e");
    --bg-color-secondary: #{$white};
    --bg-color-third: #{$white};
    --bg-color-fourth: #{$ghost-white};
    --bg-color-buy-ai-card: #{$isabelline};
    --bg-color-buy-ai-card-border: #{$eerie-black-03};
    --bg-color-subcribes-card-border: #{rgba($white, 0)};
    --bg-color-subcribes-line: #{rgba($eerie-black, 0.2)};
    --bg-color-cards: #{$white};
    --bg-color-total-payment-card: #{$whitesmoke};
    --bg-color-input: #{$eerie-black-secondary-005};
    --bg-color-menu-item: #{$tufts-blue-01};
    --bg-color-authLayout: #{$white};
    --bg-color-table: #{$whitesmoke};
    --bg-color-billing-infor: #{$isabelline};
    --bg-color-warning-toast: #{$white};
    --text-color-primary: #{$eerie-black};
    --text-color-secondary: #{$white};
    --text-color-tertiary: #{$tufts-blue};
    --text-color-input: #{$eerie-black-05};
    --text-color-inactive-pagination: #{$eerie-black-03};
    --background-stop-button: #{$eerie-black-01};
    --border-color: #{$eerie-black-03};
    --border-color-header: #{$eerie-black-05};
    --border-color-header-homepage: #{$white-05};
    --border-color-input: #{$eerie-black-05};
    --active-link-color: #{$eerie-black-008};
    --active-link-color-homepage: #{$white-008};
    --hover-header-modal: #{$anti-flash-white};
}

[data-theme='dark'] {
    --bg-color-primary: #{$white};
    --bg-color-feature: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='1443' height='693' fill='none'%3e%3cpath fill='%231a1b1e' d='M202.197 440.5C93.284 485.7 17.685 555.333 0 584.5v108h1443v-212c-23.02-31.5-101.62-71-145.66-77-59.9-8.16-181.74 5.5-275.84-182C926.902 34 821.603 0 722.5 0c-89 0-179.948 41.5-284.557 221.5-83.688 144-192.034 206-235.746 219Z'/%3e%3c/svg%3e");
    --bg-color-secondary: #{$eerie-black};
    --bg-color-third: #{$charleston-green};
    --bg-color-fourth: #{$eerie-black-secondary};
    --bg-color-buy-ai-card: #{$charleston-green};
    --bg-color-buy-ai-card-border: #{$white-03};
    --bg-color-subcribes-card-border: #{$white-01};
    --bg-color-subcribes-line: #{rgba($white, 0.2)};
    --bg-color-cards: #{$eerie-black-secondary};
    --bg-color-total-payment-card: #{$raisin-black};
    --bg-color-input: #{$white-005};
    --bg-color-menu-item: #{$tufts-blue-01};
    --bg-color-authLayout: #{$oxford-blue};
    --bg-color-table: #{$eerie-black-secondary};
    --bg-color-billing-infor: #{$raisin-black-2};
    --bg-color-warning-toast: #{$white};
    --text-color-primary: #{$white};
    --text-color-secondary: #{$eerie-black};
    --text-color-tertiary: #{$tufts-blue};
    --text-color-input: #{$white-05};
    --text-color-inactive-pagination: #{$white-03};
    --background-stop-button: #{$white-03};
    --border-color: #{$white-03};
    --border-color-header: #{$white-05};
    --border-color-header-homepage: #{$eerie-black-05};
    --border-color-input: #{$white-05};
    --active-link-color: #{$white-008};
    --active-link-color-homepage: #{$eerie-black-008};
    --hover-header-modal: #{$stormcloud-035};
}

html,
body {
    font-family: $font-base;
    font-size: 16px;
    overflow-x: hidden;
    color: $raisin-black;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

button {
    font-family: inherit;

    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

a {
    text-decoration: none;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
        transition: background-color 0s 600000s, color 0s 600000s !important;
    }
}

ul,
ol {
    margin: 0;
    padding: 0;
}

.hidden-animation,
.grecaptcha-badge {
    visibility: hidden;
}

.overflow-hidden {
    height: 100vh;
}

.u-mt {
    &-4 {
        margin-top: rem(4) !important;
    }

    &-12 {
        margin-top: rem(12) !important;
    }

    &-21 {
        margin-top: rem(21) !important;
    }

    &-24 {
        margin-top: rem(24) !important;
    }

    &-16 {
        margin-top: rem(16) !important;
    }
}

.u-mb {
    &-4 {
        margin-bottom: rem(4) !important;
    }
}

.u-ml {
    &-16 {
        margin-left: rem(16) !important;
    }

    &-10 {
        margin-left: rem(10) !important;
    }

    &-4 {
        margin-left: rem(4) !important;
    }
}

.u-mr {
    &-6 {
        margin-right: rem(6) !important;
    }
}

.color {
    @include style-color();
}

.fs {
    @include style-font-base();
}

.zalo-chat-widget {
    right: 27px !important;
    bottom: 102px !important;

    @include mobile-down {
        right: 24px !important;
    }
}

.floating-playground {
    position: fixed;
    cursor: pointer;
    width: rem(55);
    height: rem(55);
    z-index: 81;
    right: rem(32);
    bottom: rem(172);
    display: none;

    @include mobile-down {
        right: rem(28);
        display: block;
    }
}

.required {
    color: $fire-engine-red !important;
    margin-left: rem(4) !important;
}

.line-through {
    text-decoration: line-through;
    color: $silver;
}

.theme-label {
    color: var(--text-color-primary);
}

.customSwitch {
    .index-module_tfcSwitch__-nFeZ {
        border: 0.5px solid $eerie-black-05;
        background-color: $platinum2;
        min-width: rem(36);
        height: rem(18);

        &::after {
            width: rem(12);
            height: rem(12);
            background-color: $spanish-gray;
        }
    }

    ;

    .index-module_tfcSwitch-checked__0n1w8 {
        &.index-module_tfcSwitch-medium__IbgBy {
            &::after {
                left: calc(100% - rem(14));
            }
        }

    }

    &.dark {
        .index-module_tfcSwitch__-nFeZ {
            background-color: $tufts-blue;

            &::after {
                background-color: $charleston-green;

            }
        }
    }
}
