.p-infoCustomer {
    min-height: 100svh;
    height: 100%;
    padding-bottom: rem(24);
    background: linear-gradient(90deg,
    #0189d0 2.24%,
    #0181c9 7.34%,
    #015ea9 34.87%,
    #004492 60.36%,
    #003484 83.81%,
    #002f7f 102.16%);
    color: $white;

    .container {
        position: relative;
    }

    &_container {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: rem(120);
    }

    &_heading {
        text-align: center;
        text-shadow: 0 3px 10px rgba(255, 255, 255, 0.5);
        @include font-base(18, 24);

        @include mobile-down {
            @include font-base(16, 21);
        }
    }

    &_item {
        display: inline-block;

        span {
            &:first-child {
                margin-right: rem(3);
            }
        }
    }

    &_info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        margin-top: rem(24);
        gap: rem(8);
        margin-bottom: rem(24);
    }

    &_btn {
        min-height: rem(40);
        margin-top: rem(30);
        margin-bottom: rem(20);
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    &_btnGroup {
        display: flex;
        width: 100%;
        margin-bottom: rem(8);

        .a-button-p-infoCustomer_qr {
            margin-right: rem(8);
        }
    }

}

.text-danger {
    color: $fire-engine-red;
}