// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$fire-engine-red: rgb(198, 30, 30); //#c61e1e
$white-smoke: rgb(244, 246, 250); //#f4f6fa
$white-smoke-5f5: rgb(245, 245, 245); //#f5f5f5
$davys-grey: rgb(89, 89, 89); //#595959
$platinum: rgb(222, 224, 234); // #dee0ea
$platinum2: rgb(234, 234, 234); // #eaeaea
$isabelline: rgb(237, 237, 237); //#ededed
$medium-persian-blue: rgb(12, 93, 165); //0c5da5
$raisin-black: rgb(34, 34, 34); //222222
$gainsboro: rgb(217, 217, 217); //#d9d9d9
$platinum1: rgb(231, 231, 231); //#e7e7e7
$electric-indigo: rgb(105, 0, 238); //#6900ee
$ufo-green: rgb(75, 207, 81); //#4bcf51
$cadmium-red: rgb(224, 0, 26); //#e0001a
$web-golden: rgb(255, 215, 0); //#ffd700
$charleston-green: rgb(35, 39, 42); //#23272a
$davy-grey: rgb(85, 85, 85); //#555555
$anti-flash-white: rgb(241, 241, 241); //#f1f1f1
$snow: rgb(250, 250, 250); //#fafafa
$ku-crimson: rgb(232, 0, 0); //#e80000
$brilliant-azure: rgb(59, 155, 241); //#3b9bf1
$sandstorm: rgb(249, 214, 72); // #f9d648
$maya-blue: rgb(124, 194, 255); //#7cc2ff
$india-green: rgb(10, 133, 14); //#0a850e
$dark-gray: rgb(168, 168, 168); // #a8a8a8
$gainsboro-ada: rgb(218, 218, 218); //#dadada
$silver: rgb(192, 192, 192); //#c0c0c0
$mikado-yellow: rgb(250, 193, 19); //#fac113
$eerie-black: rgb(26, 27, 30); //#1a1b1e
$eerie-black-secondary: rgb(20, 21, 23); //#141517
$charleston-green: rgb(37, 38, 43); //#25262b
$isabelline: rgb(234, 236, 239); //#eaecef
$raisin-black: rgb(33, 34, 37); //#212225
$whitesmoke: rgb(244, 244, 244); //#f4f4f4
$ghost-white: rgb(248, 249, 250); //#f8f9fa
$oxford-blue: rgb(0, 32, 49); //#002031
$pale-cyan: rgb(142, 211, 247); //#8ed3f7
$tufts-blue: rgb(52, 113, 188); //#3471bc
$bleu-de-france: rgb(65, 141, 234); //#418dea
$rich-black: rgb(0, 0, 36); //#000024
$spanish-gray: rgb(160, 160, 160); //#a0a0a0
$yale-blue: rgb(25, 80, 148); //#195094
$bleu-france: rgb(65, 141, 234); //#418dea
$ruddy: rgb(249, 3, 32); //#f90320
$stormcloud: rgb(92, 95, 102); //#5c5f66
$raisin-black-2: rgb(31, 33, 35); //#1f2123
$satin-sheen-gold: rgb(207, 158, 55); //#CF9E37
$flax: rgb(233, 215, 138); //#E9D78A
$pigment: rgb(36, 62, 144); //#243E90
$red: rgb(255, 0, 0); //#FF0000

// Overlays
$raisin-black02: rgba($raisin-black, 0.2);
$black-02: rgba($black, 0.2);
$black014: rgba($black, 0.14);
$black015: rgba($black, 0.15);
$black-06: rgba($black, 0.5);
$white-05: rgba($white, 0.5);
$white-03: rgba($white, 0.3);
$white-01: rgba($white, 0.1);
$black014: rgba($black, 0.14);
$white-015: rgba($white, 0.15);
$white-008: rgba($white, 0.08);
$white-005: rgba($white, 0.05);
$medium-persian-blue-02: rgba($medium-persian-blue, 0.2);
$raisin-black-02: rgba($raisin-black, 0.2);
$raisin-black05: rgba($raisin-black, 0.5);
$raisin-black08: rgba($raisin-black, 0.8);
$eerie-black-01: rgba($eerie-black, 0.1);
$eerie-black-03: rgba($eerie-black, 0.3);
$eerie-black-05: rgba($eerie-black, 0.5);
$eerie-black-005: rgba($eerie-black, 0.05);
$eerie-black-008: rgba($eerie-black, 0.08);
$eerie-black-secondary-005: rgba($eerie-black-secondary, 0.05);
$tufts-blue-01: rgba($tufts-blue, 0.1);
$stormcloud-035: rgba($stormcloud, 0.35);
$ufo-green-05: rgba($ufo-green, 0.5);
$cadmium-red-05: rgba($cadmium-red, 0.5);
$web-golden-05: rgba($web-golden, 0.5);

// Linears
$white-linear: linear-gradient(100deg, $white, $platinum1);
$blue-liner: linear-gradient(270deg, $medium-persian-blue 0%, $electric-indigo 100%);
$tufts-blue-liner: linear-gradient(90deg, $tufts-blue 100%, $white 100%);
$blue-text-linear: linear-gradient(86.64deg, #009cde -18.11%, #243e90 100.62%);
$pale-gold-linear1: linear-gradient(80.5deg,
#f3eeac 1.7%,
$satin-sheen-gold 19.22%,
#d2a541 25.78%,
#dbb85d 31.65%,
$flax 41.98%,
#f3eeac 47.2%,
$satin-sheen-gold 71.35%,
#d2a541 75.57%,
#dbb85d 82.87%,
#e9d78a 92.28%,
#f3eeac 98.53%);
$mountbatten-pink-linear: linear-gradient(0deg,
#633115 0%,
#9f7b2d 15%,
#dcce88 36%,
#f3eeac 44%,
#dac880 70%,
#f3eeac 86%,
#75461c 100%);
$inner-border-gold-linear: linear-gradient(64.5deg,
#75461c 0.46%,
#9f7b2d 12.47%,
#dcce88 24.68%,
#f3eeac 29.27%,
#dac880 47.45%,
#c0a068 58.6%,
#c7aa71 62.91%,
#d9c689 68.89%,
#f3eeac 79.33%,
#75461c 97.07%);
$blue-linear-button: linear-gradient(360cdeg,
#185f9a -4.17%,
#3ebdf1 12.65%,
#1562fb 26.7%,
#0a4fd6 41.14%,
#0a4fd6 65.83%,
#1562fb 77.22%,
#3ebdf1 95.45%,
#0a2db3 106.64%);

$inner-border-gold-linear2: linear-gradient(6deg,
#75461c 0.46%,
#9f7b2d 12.47%,
#dcce88 24.68%,
#f3eeac 29.27%,
#dac880 47.45%,
#c0a068 58.6%,
#c7aa71 62.91%,
#d9c689 68.89%,
#f3eeac 79.33%,
#75461c 97.07%);
$input-border: linear-gradient(90deg,
#97858d -0.19%,
#e9e9e9 11.13%,
#9f9f9f 24.08%,
#efefef 40.52%,
#767676 52.8%,
#efefef 63.45%,
#a7a7a7 84.4%,
#ebebeb 100.19%);
$header-number-picking: linear-gradient(0deg,
#140c40 -0.03%,
#191d5b 18.72%,
#243e90 49.99%,
#213480 63.21%,
#181954 89.23%,
#140c40 100%);
$yellow-linear: linear-gradient(140.55deg,
#d58800 -0.07%,
#f2aa00 54.21%,
#fbd97b 138%,
#ffefb7 177.38%,
#fbe5a6 209.39%,
#f1cc79 273.05%,
#e0a431 361.43%,
#d58800 417.94%,
#eabb5b 486.3%,
#ffefb7 552.22%,
#f2d05f 611.18%);
$blue-linear-button: linear-gradient(360deg,
#185f9a -4.17%,
#3ebdf1 12.65%,
#1562fb 26.7%,
#0a4fd6 41.14%,
#0a4fd6 65.83%,
#1562fb 77.22%,
#3ebdf1 95.45%,
#0a2db3 106.64%);
