.p-scan {
    &_container {
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        // background: $black-06;
        background: linear-gradient(90deg,
        #0189d0 2.24%,
        #0181c9 7.34%,
        #015ea9 34.87%,
        #004492 60.36%,
        #003484 83.81%,
        #002f7f 102.16%);

        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        // &::after {
        //     position: absolute;
        //     content: '';
        //     background-color: $black-06;
        //     width: 100%;
        //     height: 100%;
        //     top: 0;
        //     left: 0;
        //     z-index: 2;
        // }
    }

    // &_layer {
    //     position: absolute;
    //     content: '';
    //     width: 100%;
    //     height: 100%;
    //     top: 0;
    //     left: 0;
    //     z-index: 2;
    //     display: flex;
    // }

    &_corner {
        position: relative;
        width: rem(300);
        height: rem(300);
        pointer-events: none;

        &-1 {
            position: absolute;
            top: 0;
            left: 0;
            width: rem(50);
            height: rem(50);
            border-top: rem(3) solid #0f0;
            border-left: rem(3) solid #0f0;
            border-top-left-radius: rem(16);
        }

        &-2 {
            position: absolute;
            top: 0;
            right: 0;
            width: rem(50);
            height: rem(50);
            border-top: rem(3) solid #0f0;
            border-right: rem(3) solid #0f0;
            border-top-right-radius: rem(16);
        }

        &-3 {
            position: absolute;
            bottom: 0;
            left: 0;
            width: rem(50);
            height: rem(50);
            border-bottom: rem(3) solid #0f0;
            border-left: rem(3) solid #0f0;
            border-bottom-left-radius: rem(16);
        }

        &-4 {
            position: absolute;
            bottom: 0;
            right: 0;
            width: rem(50);
            height: rem(50);
            border-bottom: rem(3) solid #0f0;
            border-right: rem(3) solid #0f0;
            border-bottom-right-radius: rem(16);
        }
    }

    &_content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &_btnRetry {
        min-height: rem(40);
        margin-top: rem(120);
        margin-bottom: rem(20);
        width: rem(200);
    }
}