.o-navigate {
    position: fixed;
    top: rem(20);
    left: 0;
    width: 100%;
    z-index: 100;

    &_back {
        display: flex;
        align-items: center;
        color: $black;
        background: $white;
        width: fit-content;
        padding: rem(4) rem(2);
        padding-right: rem(8);
        border-radius: rem(8);
    }
}
