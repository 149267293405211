.p-checkinResult {
    height: 100svh;
    padding-bottom: rem(28);
    background: linear-gradient(90deg,
    #0189d0 2.24%,
    #0181c9 7.34%,
    #015ea9 34.87%,
    #004492 60.36%,
    #003484 83.81%,
    #002f7f 102.16%);

    &_container {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .a-button {
        max-width: rem(400);
        margin-bottom: rem(24);

        &:first-child {
            margin-bottom: rem(24);
        }
    }

    h2 {
        color: $white;
        font-weight: bold;
        margin-bottom: rem(16);
    }

    p {
        color: $white;
    }
}