.a-loading {
    $root: &;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: rem(10);
    margin-top: rem(10);
    width: 100%;
    background-color: transparent;

    &-small {
        margin: 0;
    }

    .a-icon {
        height: rem(30);
        width: rem(30);
    }

    &-fullScreen {
        height: 100vh;
        left: 0;
        margin: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 10;
        background-color: rgba($black, 0.7);

        .a-icon {
            height: rem(50);
            width: rem(50);
        }
    }

    &-filled {
        background-color: $white;
    }

    &_wrapper {
        align-items: center;
        background-color: transparent;
        display: flex !important;
        justify-content: center;

        .a-text {
            margin-left: rem(8);
        }
    }

    &_tail {
        animation: tail 1s infinite linear;
        background-image: linear-gradient($white, $white), conic-gradient($white, $maya-blue);
        background-origin: border-box;
        background-clip: content-box, border-box;
        border: double 0.25em transparent;
        border-radius: 100%;
        height: rem(32);
        overflow: hidden;
        position: relative;
        width: rem(32);

        &-small {
            width: rem(24);
            height: rem(24);
        }
    }

    &-white {
        #{$root}_tail {
            background-image: linear-gradient($black, $black), conic-gradient($black, $white);
        }
    }

    &-isTransparent {
        background-color: transparent;
    }

    &-abs {
        position: absolute;
        width: fit-content;
        height: fit-content;
        top: 0;
        left: 0;
        margin-top: 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@keyframes tail {
    to { transform: rotate(1turn); }
}
