.a-text {
    @include font-base(20, 28);

    &-md {
        @include font-base(26, 40);

        @include tablet-down {
            @include font-base(18, 20);
        }
    }

    &-md-lg {
        @include font-base(24, 33);

        @include tablet-down {
            @include font-base(20, 27);
        }
    }

    &-md-sm {
        @include font-base(26, 33);

        @include tablet-down {
            @include font-base(16, 19);
        }
    }

    &-16x22 {
        @include font-base(18, 24);
    }

    &-22x26 {
        @include font-base(24, 26);

        @include mobile-down {
            @include font-base(18, 22);
        }
    }

    &-19x26 {
        @include font-base(19, 26);

        @include tablet-down {
            @include font-base(14, 18);
        }
    }

    &-20x24 {
        @include font-base(20, 24);

        @include mobile-down {
            @include font-base(16, 19);
        }
    }

    &-24x33 {
        @include font-base(24, 33);

        @include tablet-down {
            @include font-base(18, 22);
        }
    }

    &-32x38 {
        @include font-base(32, 38);

        @include tablet-down {
            @include font-base(24, 33);
        }
    }

    &-30x40 {
        @include font-base(30, 40);

        @include tablet-down {
            @include font-base(20, 28);
        }
    }

    &-sm {
        @include font-base(16, 24);
    }

    &-16x19 {
        @include font-base(16, 24);
    }

    &-12x16 {
        @include font-base(14, 18);
    }

    &-8x10 {
        @include font-base(10, 14);
    }

    &-blueLinear {
        background-clip: text;
        background-image: $blue-text-linear;
        color: transparent;
    }

    &-yellowLinear {
        background-clip: text;
        background-image: $yellow-linear;
        color: transparent;
    }

    &-prizeGradient {
        background-clip: text;
        background-image: linear-gradient(90deg, #0a2866 30%, #0b8dcd 53.72%, #0a2866 100%);
        color: transparent;
    }

    &-prizeNumber {
        background-clip: text;
        background-image: linear-gradient(101deg, #7a4d22 0%, #efe8a7 31.25%, #c2a36b 53.94%, #cab66e 72.41%, #b98300 92.32%);
        color: transparent;
    }

    @include style-color();
    @include style-font-weight();
    @include style-general();
    /* @include font-family(); */
}