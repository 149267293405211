.o-popup {
    $root: &;
    border: 0;
    border-radius: 0;
    color: $white;
    max-height: rem(640);
    max-width: rem(797);
    outline: 0;
    overflow: auto;
    position: fixed;
    width: 100%;

    &-otp {
        max-width: rem(578);
    }

    @include tablet-down {
        max-height: rem(601);
    }

    // @include scroll-bars;

    @include mobile-tablet {
        max-width: 80vw;
    }

    @include mobile-down {
        max-width: 90vw;
    }

    &_title {
        align-items: center;
        background-clip: padding-box, border-box;
        background-origin: border-box;
        border: solid rem(2.5) transparent;
        border-radius: rem(10);
        display: flex;
        justify-content: center;
        margin: auto;
        max-width: rem(400);
        position: relative;
        text-align: center;

        #{$root}-otp & {
            max-width: rem(220);
        }

        &:not(#{$root}-otp) {
            padding: rem(10) rem(15);
        }

        @include mobile-down {
            height: auto;
            padding: rem(10) rem(35);
            @include font-base(18, 24);
        }

        &::before {
            border-radius: rem(10);
            content: '';
            height: calc(100% - #{rem(2.5)});
            position: absolute;
            width: calc(100% - #{rem(2.5)});
        }

        span {
            color: $pigment;
            font-weight: 700;
            position: relative;
            @include font-base(20, 27);

            @include tablet-down {
                @include font-base(20, 27);
            }
        }
    }

    &_close {
        cursor: pointer;
        height: rem(28);
        position: absolute;
        right: rem(21);
        top: rem(25);
        width: rem(28);
        z-index: 3;
    }

    &-container {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        background: rgba(14, 14, 14, 0.65);
        // border: 1px solid $uni-california-gold;
        overflow: auto;
        position: relative;
        width: 100%;

        &-noScrollContainer {
            overflow: hidden;
        }
    }

    &:focus {
        outline: 0;
    }

    &-overlay {
        animation: none;
        background: rgba($black, 0.3) !important;
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: $z-modal;

        &::after {
            backdrop-filter: blur(8px);
            background: rgba($black, 0.74) !important;
            content: '';
            display: block;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: -1;
        }
    }

    &_body {
        background: linear-gradient(161.19deg,
        #fff 0.96%,
        #ccc 24.24%,
        #fff 54.65%,
        #ccc 80.67%,
        #fff 99.03%);
        padding: rem(42) rem(32);
        position: relative;

        &::before {
            /* background: transparent url('~assets/images/popup-texture.png') no-repeat center/100% auto; */
            content: '';
            height: 75%;
            left: 50%;
            pointer-events: none;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 75%;
        }

        @include small-mobile {
            padding: rem(30) rem(14);
        }

        #{$root}-otp & {
            padding-left: rem(47);
            padding-right: rem(47);

            @include tablet-down {
                padding-left: rem(24);
                padding-right: rem(24);
            }
        }
    }

    &_content {
        margin-top: rem(14);

        #{$root}-otp & {
            margin-top: rem(16);
        }

        @include mobile-down {
            margin-top: rem(14);
        }
    }

    &_button {
        margin: 0 auto;
        max-width: rem(265);

        &.mobile-reverse {
            @include mobile-down {
                display: flex;
                flex-direction: column-reverse;
            }

            .a-button {
                &:first-child {
                    @include mobile-down {
                        margin-top: rem(16);
                    }
                }

                &:last-child {
                    margin-top: 0 !important;
                }
            }
        }

        &.flex {
            display: flex;
            justify-content: space-between;
            max-width: 100%;

            .a-button {
                max-width: rem(200);
            }
        }
    }

    &-otp {
        .m-otp-input {
            margin-top: rem(21.42);
        }
    }

    &-success {
        max-width: rem(690);

        .a-text-royalAzure {
            @include mobile-down {
                font-size: rem(14);
            }
        }
    }

    &-scanning {
        #{$root}_title {
            max-width: rem(286);
        }
    }

    &-context {
        max-width: rem(573);

        .o-popup_content {
            margin-left: auto;
            margin-right: auto;
            max-width: rem(343);
        }
    }

    &-selected-ball {
        max-width: rem(681);

        @include mobile-down {
            max-height: 94%;
        }

        .submit {
            margin-top: rem(32);

            .a-button {
                margin-left: auto;
                margin-right: auto;
                width: rem(189);
            }
        }

        .slick {
            /* background-image: url('~assets/images/ball/wrap_ball.svg'); */
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            height: rem(88);
            margin-left: auto;
            margin-right: auto;
            max-width: rem(529);
            padding-left: rem(12);
            padding-top: rem(8);

            @include tablet-down {
                height: rem(78);
                max-width: rem(480);
                padding-left: rem(24);
            }

            @include small-mobile {
                /* background-image: url('~assets/images/ball/wrap_ball_sm.svg'); */
                background-size: contain;
                padding-left: rem(10);
                padding-top: rem(10);
                width: rem(282);
            }

            &-arrow {
                height: rem(40);
                width: rem(42);

                &::before {
                    content: none;
                }

                @include small-mobile {
                    height: rem(24);
                    width: rem(22);
                }
            }

            &-prev {
                /* background-image: url('~assets/images/ball/prev.svg'); */
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                left: rem(-64);

                @include tablet-down {
                    left: rem(-84);
                }

                @include small-mobile {
                    left: rem(-42);
                }
            }

            &-next {
                /* background-image: url('~assets/images/ball/next.svg'); */
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                right: rem(-54);

                @include tablet-down {
                    right: rem(-64);
                }

                @include small-mobile {
                    right: rem(-32);
                }
            }
        }
    }

    &-timeout {
        max-width: rem(578);

        .submit {
            margin-top: rem(32);

            .a-button {
                margin-left: auto;
                margin-right: auto;
                width: auto;

                @include tablet-down {
                    width: rem(175);
                }
            }
        }
    }

    &-numberReview {
        max-width: rem(681);
    }

    &-confirmNumber {
        max-width: rem(681);

        @include break-min(1366px) {
            max-height: rem(714);
        }

        @include mobile-down {
            max-height: 94%;
        }

        .o-popup_body {
            @include tablet-up {
                padding-left: rem(64);
                padding-right: rem(64);
            }
        }

        .o-popup_button {
            @include tablet-up {
                display: flex;
                justify-content: center;
                max-width: 100%;
            }

            .a-button {
                @include tablet-up {
                    margin-left: rem(12);
                    margin-right: rem(12);
                    width: rem(185);
                }

                @include tablet-down {
                    &:last-child {
                        margin-top: rem(16);
                    }
                }
            }
        }
    }

    &-player {
        background-image: none !important;
        max-height: unset;
        max-width: 140vh;
        overflow: hidden;

        @include tablet-down {
            padding: rem(5);
        }

        @include mobile-tablet {
            max-width: 72vw;
        }

        #{$root} {
            &_wrapper {
                display: flex;
                height: 100%;
                padding: 0;
                width: 100%;

                @include mobile-down {
                    padding: 0 !important;
                }
            }

            &_body {
                display: flex;
                margin-top: 0;
                width: 100%;
            }
        }
    }

    &-confirmSelfie {
        max-width: rem(589);

        #{$root}_body {
            background: $pigment;
            padding-bottom: rem(20);
            padding-top: rem(20);
        }

        #{$root}_content {
            margin: 0;
        }

        #{$root}_button {
            margin-top: rem(30);
        }
    }
}

.o-modal-portal-open {
    opacity: 1;
}

.reactmodal-body-open,
.reactmodal-html-open {
    overflow: hidden;
}

.ReactModal__Overlay {
    opacity: 0;
    transform: scale(0) translateX(-100px);
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: scale(1) translateX(-100px);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: scale(0);
}

.ReactModal__Overlay {
    align-items: center;
    background: rgba($black, 0.3) !important;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    // transform: translateY(-100%);
    transition: all 500ms ease-in-out;
    z-index: $z-modal;

    &::after {
        background: rgba($black, 0.74) !important;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }

    &--open {
        overflow: hidden;

        @include tablet-up {
            padding-right: rem(7);
        }
    }

    &--after-open {
        opacity: 1;
        transform: scale(1);
    }

    &--before-close {
        opacity: 0;
        transform: scale(0);
    }
}

.overflow {
    overflow: hidden;
}